import React, { useState } from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import { ImageWithCaption } from '../components/ImageWithCaption'
import { Layout } from '../components/Layout'
import { Modal } from '../components/Modal'
import { RawContentSection } from '../components/RawContentSection'
import { SEO } from '../components/SEO'
import { withEnlarge } from '../components/withEnlarge'

import { toProcessData } from '../transform'

const Process = withEnlarge(
  ({
    data: {
      prismic: { process_page },
    },
    handleEnlarge,
  }) => {
    const { description, steps, title } = toProcessData(process_page)

    return (
      <Layout>
        <SEO title={RichText.asText(title)} />
        <section>
          <div className="bg-white px-4 py-8 md:py-12 lg:py-16">
            <div className="container max-w-6xl">
              <h1 className="text-5xl md:text-6xl max-w-xl mx-auto">{RichText.asText(title)}</h1>
              <div className="mt-4">
                <RawContentSection content={<RichText render={description} />} />
              </div>
            </div>
          </div>
          <div className="bg-gray-200 px-4 py-8 md:py-12 lg:py-16">
            <div className="flex flex-wrap max-w-site mx-auto">
              {steps.map(({ caption, image, stepTitle }, i) => (
                <div
                  key={image.src}
                  className="w-full sm:w-1/2 lg:w-1/3 px-2 group"
                  onClick={() =>
                    handleEnlarge({
                      caption: caption ? RichText.asText(caption) : null,
                      image,
                      title: `${i + 1}. ${stepTitle ? RichText.asText(stepTitle) : ''}`,
                    })
                  }
                >
                  <div className="max-w-3xl mx-auto md:-mb-4">
                    <h2 className="text-2xl lg:text-3xl group-hover:underline">{`${i + 1}. ${
                      stepTitle ? RichText.asText(stepTitle) : ''
                    }`}</h2>
                  </div>
                  <ImageWithCaption
                    caption={caption ? RichText.asText(caption) : null}
                    className="cursor-pointer"
                    image={{ ...image, aspectRatio: 1 }}
                    title={RichText.asText(title)}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      </Layout>
    )
  }
)

export const query = graphql`
  {
    prismic {
      process_page(lang: "en-nz", uid: "process") {
        ...processPage
      }
    }
  }
`

export default Process
